import { useEffect } from 'react';
import overlay from '@activebrands/core-web/libs/overlay';
import extractQueryParams from '@grebban/utils/string/extractQueryParams';

const REACT_APP_SIGN_IN_QUERY_PARAM = process.env.REACT_APP_SIGN_IN_QUERY_PARAM || '';
const REACT_APP_SIGN_UP_QUERY_PARAM = process.env.REACT_APP_SIGN_UP_QUERY_PARAM || '';

const useSignInSignUpQueryParams = () => {
    useEffect(() => {
        const queryParams = extractQueryParams(window?.location?.search);
        const openSignInOverlay = queryParams[REACT_APP_SIGN_IN_QUERY_PARAM] === true;
        const openSignUpOverlay = queryParams[REACT_APP_SIGN_UP_QUERY_PARAM] === true;

        if (!openSignInOverlay && !openSignUpOverlay) return;

        requestAnimationFrame(() => {
            if (openSignInOverlay) {
                overlay.open('unauthenticated', {
                    form: 'sign-in',
                    redirect: false,
                });
            }
            if (openSignUpOverlay) {
                overlay.open('unauthenticated', {
                    form: 'sign-up-step-one',
                    resetId: 'new-member',
                });
            }
        });
    }, []);
};

export default useSignInSignUpQueryParams;
